html, body {
  height: 100%;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

#c {
  width: 100%;
  height: 100%;
  display: block;
}

a, a:visited {
  color: #fff;
}

.credit-overlay {
  color: #fff;
  background-color: #000c;
  border-radius: .2rem;
  margin: 1rem;
  padding: .5rem;
  position: absolute;
  bottom: 0;
  right: 0;
}

/*# sourceMappingURL=index.bf1ecbd0.css.map */
