html,
body {
  margin: 0;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

#c {
  width: 100%;
  height: 100%;
  display: block;
}

a, a:visited {
  color: white;
}

.credit-overlay {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 1rem 1rem;
  padding: 0.5rem 0.5rem;
  background-color: rgba(0.1, 0.1, 0.1, 0.8);
  border-radius: 0.2rem;
  color: white;
}